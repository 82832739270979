import React from 'react'
import Link from 'gatsby-link'

export default class CaseStudyStepCard extends React.Component {

  render() {
    return (   
      
      <div className="aga-card">
        <h3 className="aga-card-title">{ this.props.title }</h3>
        <p className="aga-card-body">{ this.props.body }</p>
        <Link to={ this.props.link }><footer className="aga-card-footer">{ this.props.footer }</footer></Link>
      </div>
    )
  }
}