import React from 'react'

import Layout from '../../../components/Layout'
import CaseStudyNav from '../../../components/CaseStudyNav'
import CaseStudyStepSummary from '../../../components/CaseStudyStepSummary'
import CaseStudyStepCard from '../../../components/CaseStudyStepCard'
import CaseThreePodcast from '../../../media/2018-10-22-PODCAST_Health_Maintenance.mp3'
import CaseThreeTranscript from '../../../media/2018-10-30-PODCAST_Health_Maintenance_transcript.pdf'

export default class DiagnosticCaseStudy extends React.Component {
  // Handles fade-in by detecing when the component fully mounts, then doing fade-in
  componentDidMount() {
    document.getElementById('aga-case-anchor').classList.add('aga-fade-in')
  }

  render() {
    return (
      <div>
        <Layout>
          <CaseStudyNav 
            linkOneClass="aga-casestudy-link"
            linkTwoClass="aga-casestudy-link"
            linkThreeClass="aga-casestudy-link active"
          />
          
          <div id="aga-case-anchor">

            {/* Part 3 */}
            <div>
              <CaseStudyStepSummary
                partSubtitle="Health Maintenance – Primary Care"
                partSummary={[
                  <p key="OPIROPIEQR0QR0">About a year after her initial Crohn’s diagnosis, Jennifer returns to you, her primary care physician, for a regular health maintenance visit. Her gastroenterologist recently started her on biologic therapy for her Crohn’s Disease.  She feels relatively well on the new therapy and mentions that she and her husband are starting to think about having another baby.</p>,
                  <h3 key="PQOCMNVXNVMX">Labs</h3>,
                  <ul key="FSGLKADFJ89IJO23KL">
                    <li key="356874351211">TSH 2.0 nl</li>
                    <li key="LKJ45934J5LKERJ">Hb 12 g/dL</li>
                  </ul>
                ]}
                mediaTitle="Episode Three"
                mediaSubtitle="IBD Health Maintenance"
                podcast={ CaseThreePodcast } 
                podcastName="Episode-Three-IBD-Health-Maintenance.mp3"
                podcastTranscript={ CaseThreeTranscript }
                podcastTranscriptName="Episode-Three-IBD-Health-Maintenance-transcript.pdf"       
              />
            </div>
            <article className="aga-cs-container-short" id="part-3">
              <h2 className="aga-cs-question-title">Case 3: Step</h2>
              <section className="aga-card-row">
                <CaseStudyStepCard 
                  title="Step 6"
                  body="How will you monitor the success of this patient’s medication? What screening and preventive measures would you take to minimize risks to her physical and mental health?"
                  footer="View Step"
                  link="/steps/step-6"
                />
              </section>
            </article>   
          </div>
        </Layout>
      </div>
    )
  }
}
