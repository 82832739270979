import React from 'react'
import AudioPlayer from '../../components/AudioPlayer' 

export default class CaseStudyStepSummary extends React.Component {

  render() {
    return (   
      <div> 
        <section className="lightest-gray-bg">
          <section className="aga-cs-container-short">
            <section className="aga-container">
              <h2 className="aga-cs-question-title">{ this.props.partSubtitle }</h2>
              { this.props.partSummary }
              <h3 className="center">Clinical Conversations: IBD Podcast</h3>
              { typeof window !== 'undefined' && AudioPlayer && 
                <AudioPlayer 
                  mediaTitle={ this.props.mediaTitle }
                  mediaSubtitle={ this.props.mediaSubtitle }
                  src={ this.props.podcast } 
                />
              }
              <p className="center">
                Download: <a role="button" href={ this.props.podcast } download={ this.props.podcastName }>Audio File</a> | <a role="button" href={ this.props.podcastTranscript } download={ this.props.podcastTranscriptName }>PDF Transcript</a>
              </p>
            </section>
          </section>
        </section>
      </div>
    )
  }
}